import { Suspense, useEffect } from 'react';
import LeftNav from '../components/layout/LeftNav';
import PageLoader from '../components/shared/page-loader/PageLoader';
import { FCWithChildren } from '../types/FCWithChildren';
import { useLayoutMargins } from '../global-state/Layout';

const PublicLayout: FCWithChildren<unknown> = (props) => {
  const { children } = props;
  const setLayoutMargins = useLayoutMargins((x) => x.setValue);

  useEffect(() => {
    setLayoutMargins('ml-20');

    return () => setLayoutMargins('');
  }, [setLayoutMargins]);

  return (
    <>
      <aside className="bg-background-sidebar fixed flex h-full flex-col items-center shadow">
        <LeftNav />
      </aside>

      <div className={'ml-20 flex flex-1 flex-col'}>
        <Suspense fallback={<PageLoader loading isSuspense />}>{children}</Suspense>
      </div>
    </>
  );
};

export default PublicLayout;
